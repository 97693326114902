import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle']

  initialize () {
    this.keyK = this.keyK.bind(this)
    this.keyEsc = this.keyEsc.bind(this)
  }

  connect () {
    this.open = false
    document.addEventListener('keydown', this.keyK)
  }

  disconnect () {
    document.removeEventListener('keydown', this.keyK)
  }

  toggle (e) {
    e.preventDefault()
    if (this.open) { // CLOSE
      document.removeEventListener('keydown', this.keyEsc)
      // | Leaving: "transition ease-in duration-75"
      // | From: "transform opacity-100 scale-100"
      // | To: "transform opacity-0 scale-95"
      this.toggleTarget.classList.replace('ease-out', 'ease-in')
      this.toggleTarget.classList.replace('duration-200', 'duration-75')
      this.toggleTarget.classList.replace('opacity-100', 'opacity-0')
      this.toggleTarget.classList.replace('scale-100', 'scale-95')
      this.toggleTarget.classList.toggle('hidden')
    } else { // OPEN
      document.addEventListener('keydown', this.keyEsc)
      // | Entering: "transition ease-out duration-200"
      // | From: "transform opacity-0 scale-95"
      // | To: "transform opacity-100 scale-100"
      this.toggleTarget.classList.toggle('hidden')
      this.toggleTarget.classList.replace('ease-in', 'ease-out')
      this.toggleTarget.classList.replace('duration-75', 'duration-200')
      this.toggleTarget.classList.replace('opacity-0', 'opacity-100')
      this.toggleTarget.classList.replace('scale-95', 'scale-100')

      this.toggleTarget.querySelector('input[type=search]').focus()
    }

    this.open = !this.open
  }

  keyK (e) {
    if (e.key === 'k' && e.metaKey) {
      e.preventDefault()
      this.toggle(e)
    }
  }

  keyEsc (e) {
    if (e.key === 'Escape') {
      e.preventDefault()
      this.toggle(e)
    }
  }
}
